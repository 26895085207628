@import "~bootstrap/scss/bootstrap";

.red-border {
  border: 1px solid rgba(251, 129, 130, 0.4);
}

#readme {
  img {
    max-width: 100%;
  }
}